import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"

const Menu = ({ menu, current }) => {
  const list = menu.edges.map(item => (
    <li key={item.node.frontmatter.path}>
      <Link
        className={classnames(
          "block p-4 text-grey-darker font-bold border-blue hover:bg-grey-lighter no-underline",
          {
            "border-r-4": item.node.frontmatter.title === current.title,
          }
        )}
        to={item.node.frontmatter.path}
      >
        {item.node.frontmatter.title}
      </Link>
    </li>
  ))

  return (
    <div className="bg-white shadow w-full my-2">
      <ul className="list-reset">{list}</ul>
    </div>
  )
}

export default Menu
